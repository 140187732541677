import { t } from '@/i18n/translation_util';
import React from 'react';
import { useIsClient } from 'usehooks-ts';

import { trialDays } from '@listening/shared';
import { Skeleton } from './ui/skeleton';

export function TrialDaysABTesting({
  className,
  skeletonClassName,
  translationKey,
  element,
}: {
  className: string;
  skeletonClassName: string;
  translationKey:
    | 'offer.tryFree.days'
    | 'pricing.giveItATry-2'
    | 'faq.cancelTrial';
  element: 'h2' | 'h3' | 'div';
}) {
  const isClient = useIsClient();

  if (!isClient) {
    return <Skeleton className={skeletonClassName} />;
  }

  return React.createElement(element, {
    className: className,
    dangerouslySetInnerHTML: {
      __html: t(translationKey, { trialDays }),
    },
  });
}
